import { Center, Spinner } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import EditTasks from '../components/Dashboard/Tasks/EditTasks';



const HomePage = React.lazy(() => import('../components/Dashboard/HomePage'))
const Login = React.lazy(() => import('../components/AuthComponents/Login/Login'))
const Signup = React.lazy(() => import('../components/AuthComponents/Signup/Signup'))
const ChangePassword = React.lazy(() => import('../components/AuthComponents/Action/ChangePassword'))
const PasswordReset = React.lazy(() => import('../components/AuthComponents/Action/PasswordReset'))
const VerificationSent = React.lazy(() => import('../components/AuthComponents/Action/VerificationSent'))
const SentPasswordResetLink = React.lazy(() => import('../components/AuthComponents/Action/SentPasswordResetLink'))
const Settings = React.lazy(() => import('../components/AuthComponents/Login/Login'))
const Wrapper = React.lazy(() => import('./Wrapper'))
const Action = React.lazy(() => import('../components/AuthComponents/Action/Action'))

export const Routes = (params) => {
    return (
        <Suspense fallback={<Center><Spinner></Spinner></Center>}>
            <BrowserRouter>
                <Switch>
                    <Route path={'/'} exact component={Wrapper} />
                    <Route path='/login' exact component={Login} />
                    <Route path='/signup' exact component={Signup} />
                    <Route path='/dashboard' exact component={HomePage} />
                    <Route path='/settings' exact component={Settings} />
                    <Route path={'/verificationsent'} exact component={VerificationSent} />
                    <Route path={'/action'} exact component={Action} />
                    <Route path={'/passwordreset'} exact component={PasswordReset} />
                    <Route path={'/sentpasswordreset'} exact component={SentPasswordResetLink} />
                    <Route path={'/changepassword'} exact component={ChangePassword} />
                    <Route path={'/edittask'} exact component={EditTasks} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}
