import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        base:
        {
            50: '#e6e9ec',
            100: '#c1c8d0',
            200: '#98a3b1',
            300: '#6e7e92',
            400: '#4f627a',
            500: '#304663',
            600: '#2b3f5b',
            700: '#243751',
            800: '#1e2f47',
            900: '#132035',
        }
    },
    components: {
        Tooltip: {
            variants: {
                primary: {
                    backgroundColor: "#304663"
                }
            }
        },
        Heading: {
            variants: {
                smallHeading: {
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "sm",
                    opacity: '0.6',
                    letterSpacing: '0.2rem'
                },
                BannerHeading: {
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "lg",
                    letterSpacing: '0.2rem'
                },
                smallBanner: {
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: "lg",
                    letterSpacing: '0.2rem',
                    color: '#304663',
                }
            }
        },
        Text: {
            variants: {
                heading: {
                    fontFamily: "Poppins",
                    fontSize: "md"
                },
                smallText: {
                    fontFamily: "Poppins",
                    fontSize: "sm"
                }
            }
        },
        Button: {
            variants: {
                titlebarBtn: {
                    borderRadius: '0px',
                    backgroundColor: '#304663',
                    height: '32px',
                    width: '24px',
                    boxShadow: 'none',
                    color: 'white',
                    _hover: {
                        color: 'rgba(255,255,255,0.8)',
                        bg: "rgba(255,255,255,0.1)",
                        border: 'none',
                        borderRadius: '0px',
                        boxShadow: 'none',
                    },
                    _active: {
                        color: 'rgba(255,255,255,0.8)',
                        bg: "rgba(255,255,255,0.1)",
                        border: '0px',
                        borderRadius: '0px',
                        boxShadow: 'none',
                    },
                    _focus: {
                        borderRadius: '0px',
                        backgroundColor: '#304663',
                        height: '32px',
                        width: '24px',
                        boxShadow: 'none',
                        color: 'white',
                    }
                },
                windowsBtn: {
                    borderRadius: '0px',
                    backgroundColor: '#304663',
                    height: '32px',
                    width: '24px',
                    boxShadow: 'none',
                    _hover: {
                        color: '#304663',
                        bg: "rgba(255,255,255,0.1)",
                        border: 'none',
                        borderRadius: '0px',
                        boxShadow: 'none',
                    },
                    _active: {
                        color: '#304663',
                        bg: "rgba(255,255,255,0.1)",
                        border: '0px',
                        borderRadius: '0px',
                        boxShadow: 'none',
                    },
                    _focus: {
                        color: '#304663',
                        bg: "#304663",
                        border: 'none',
                        borderRadius: '0px',
                        boxShadow: 'none',
                    }
                },
                windowsCloseBtn: {
                    borderRadius: '0px',
                    backgroundColor: '#304663',
                    height: '32px',
                    width: '24px',
                    _hover: {
                        backgroundColor: "#E81123",
                        border: '0px solid',
                        boxShadow: 'none',
                        borderRadius: '0px',
                    },
                    _active: {
                        backgroundColor: "#F1707A",
                        border: '0px solid',
                        boxShadow: 'none',
                        borderRadius: '0px',
                    },
                    _focus: {
                        backgroundColor: '#304663',
                        border: '0px solid',
                        boxShadow: 'none',
                        borderRadius: '0px',
                    }
                },
                profileButtonSecondary: {

                    borderRadius: '4px',
                    color: '#304663',
                    bg: 'transparent',
                    border: "1px solid #304663",
                    _active: {
                        transform: "scale(0.8, 0.8);"
                    },
                    _hover: {
                        bg: "#304663",
                        color: 'white',
                        shadow: "md",
                    },
                    _focus: {
                        boxShadow: "inset 0.0px 0.0px #304663",
                        shadow: "md",
                    }
                },
                upgradeButton: {
                    shadow: "md",
                    borderRadius: '4px',
                    bg: "white",
                    textColor: "#304663",
                    _active: {
                        transform: "scale(0.8, 0.8)",
                        shadow: "md",
                    },
                    _focus: {
                        boxShadow: "inset 0.0px 0.0px #304663",
                        shadow: "md",
                    }
                },
                profileButton: {
                    shadow: "md",
                    borderRadius: '4px',
                    bg: "#304663",
                    textColor: "white",
                    _active: {
                        transform: "scale(0.8, 0.8)",
                        shadow: "md",
                    },
                    _focus: {
                        boxShadow: "inset 0.0px 0.0px #304663",
                        shadow: "md",
                    }
                },
                formButton: {
                    w: '100%',
                    h: '50',
                    shadow: "md",
                    borderRadius: '10px',
                    bg: "#304663",
                    textColor: "#e7e7e7",
                    _hover: { color: '#304663', bg: '#e7e7e7' },
                    _focus: {
                        boxShadow: "0.0px 0.0px #2c2b3c",
                        shadow: "md",
                    },
                    _active: {
                        transform: "scale(0.9, 0.9);",

                        shadow: "md",
                    },
                },
                socialButton: {
                    shadow: "md",
                    bg: 'white',
                    _active: {
                        transform: "scale(0.9, 0.9);",
                        boxShadow: "0.0px 0.0px #304663",
                    },
                    _hover: { color: '#304663', bg: '#e7e7e7', boxShadow: "0.0px 0.0px #304663", },
                    _focus: {
                        boxShadow: "0.0px 0.0px #304663",
                    },

                },
                Btnprimary: {
                    border: "0.2px solid white",
                    _active: {
                        transform: "scale(0.8, 0.8);"
                    },
                    _hover: { color: '#304663', bg: '#e7e7e7' },
                    _focus: {
                        boxShadow: "0.0px 0.0px #304663",

                    }
                },
                Btnaccent: {
                    border: "0.2px solid white",
                    color: "white",
                    bgColor: "#304663",
                    _active: {
                        transform: "scale(0.8, 0.8);"
                    },
                    _hover: { color: '#304663', bg: '#e7e7e7' },
                    _focus: {
                        boxShadow: "0.0px 0.0px #304663",
                    }
                },
                iconBtnprimary: {
                    border: "0.2px solid white",
                    color: "#304663",
                    bgColor: "white",
                    _active: {
                        boxShadow: "inset 0.3px 0.4px #304663",
                        border: "0.2px solid #304663"
                    },
                    _hover: { color: '#304663', bg: '#e7e7e7' },
                    _focus: {
                        boxShadow: "0.0px 0.0px #304663",

                    }
                },
                linkFocusNone: {
                    border: "none",

                },
                NavButton: {
                    _active: {
                        boxShadow: "2px 1px #304663",
                        borderColor: "#e7e7e7",
                    },
                    _hover: {
                        cursor: "pointer",
                    },
                    _focus: {
                        boxShadow: "0px 0px #304663",
                        borderColor: "#e7e7e7"
                    }
                }
            }
        },
        PopoverTrigger: {
            _active: {
                boxShadow: "2px 1px #304663",
                borderColor: "#e7e7e7"
            },
            _focus: {
                boxShadow: "0px 0px #304663",
                borderColor: "#e7e7e7"
            }
        },
        CloseButton: {
            variants: {
                btnClose: {
                    border: "0.2px solid white",
                    _active: {
                        transform: "scale(0.8, 0.8);"
                    },
                    _hover: { color: '#304663', bg: '#e7e7e7' },
                    _focus: {
                        boxShadow: "0.0px 0.0px #304663",
                    }
                },
            }
        },
        Drawer: {
            variants: {
                permanent: {
                    dialog: {
                        pointerEvents: 'auto',
                    },
                    dialogContainer: {
                        pointerEvents: 'none',
                    },
                },
            },
        },
    },
});


export default theme;