import React, { useContext, useState, useEffect } from "react"
import { auth, fireabase_App, firebase_database, firebase_firestore } from '../Firebase/Firebase'
import firebase from 'firebase/compat/app'

export const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}
export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [userProfileContent, setuserProfileContent] = useState()
    const [userSettings, setuserSettings] = useState("")
    const [userPlan, setuserPlan] = useState()
    const [userTaskAnalytics, setuserTaskAnalytics] = useState()
    const [loading, setLoading] = useState(true)
    const [isElectron, setisElectron] = useState(false)
    const [taskNameList, settaskNameList] = useState([])
    const [isTasksDisabled, setIsTasksDisabled] = useState(false)
    const [taskList, setTaskList] = useState()
    const users_authenticatedRef = firebase_firestore.collection("users_authenticated")

    function changeList(data) {
        settaskNameList(data)
    }


    const planDetails = {
        "Freemium":
        {
            "tasksLimit": 10,
            "runTime": "3600",
        },
        "Standard":
        {
            "tasksLimit": 0,
            "runTime": "0",

        },
        "Premium":
        {
            "tasksLimit": 0,
            "runTime": "0",

        }
    }
    var tokenId_user = ''


    function getElectron() {
        try {
            window.api.isElectron()
            setisElectron(true)
            return true
        }
        catch {
            setisElectron(false)
            return false
        }
    }
    function isLoggedIn() {
        return currentUser ? true : false
    }
    function setTokenId(id) {
        tokenId_user = id
    }
    function getTokenId() {
        return tokenId_user
    }
    function getUserId() {
        return auth.currentUser.uid
    }
    async function googleSignin(app = false, idtoken = undefined) {

        var provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope('profile')
        provider.addScope('email')
        provider.setCustomParameters({
            prompt: 'select_account'
        })
        try {
            const result = await fireabase_App.auth().signInWithPopup(provider).then((result) => {
            })


            auth.currentUser.getIdToken().then((token) => {
                users_authenticatedRef.doc(auth.currentUser.email).set({
                    uid: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    authtoken: token,
                    isverified: auth.currentUser.emailVerified === true ? "True" : "False",
                    loginstate: 'True',
                    lastloggedin: firebase.firestore.Timestamp.now(),
                    profileCounter: 5
                })
                users_authenticatedRef.doc(auth.currentUser.email).collection("settings").doc("billing").set({
                    currentPlan: "Freemium",
                    planEndingDate: firebase.firestore.Timestamp.now(),
                })
                users_authenticatedRef.doc(auth.currentUser.email).collection("settings").doc("notifications").set({
                    autoUpdates: true,
                    newDeals: true
                })
                users_authenticatedRef.doc(auth.currentUser.email).collection("settings").doc("tasks-settings").set({
                    "totalruntime": 0,
                    "voiceactivationleft": 3600,
                })
                return token
            })
            if (app === true) {
                auth.currentUser.getIdToken().then((token) => {
                    firebase_database.ref(`users_authenticated/${idtoken}/`).set({
                        "login": "True",
                        "email": auth.currentUser.email,
                        "uid": auth.currentUser.uid,
                        "authtoken": token,
                    })
                })

            }
            return result
        } catch (err) {
        }
    }

    function getTaskList() {
        // return taskList.filter((task) => task.name.includes("ad-"))
        return taskList
    }

    function updateTaskList(data) {
        console.log("Current Task list is: ", data);
        setTaskList(data)
    }
    async function forgotPassword(email) {
        await auth.sendPasswordResetEmail(email)
            .then(function () {
                alert('Please check your email...')
                return true
            }).catch(function (e) {
                var errorMessage = ""
                switch (e.code) {

                    case "auth/user-not-found":
                        errorMessage = "No user found with this email."
                        break

                    // eslint-disable-next-line 
                    case "auth/operation-not-allowed":
                        errorMessage = "Server error, please try again later."
                        break

                    case "invalid-email":
                        errorMessage = "Email address is invalid."
                        break

                    default:
                        errorMessage = "There was an unknown error. Please retry loggin in or try again later. If the problem persist then kindly contact us."
                        break
                }
                alert(errorMessage)
            })
    }
    // async function updateUserPasswd(password){
    //     return auth.currentUser.updatePassword(password).then(function(){
    //     }).catch(function (error){
    //         return error
    //     })
    // }
    async function updateUserPasswd() {
        return await forgotPassword(auth.currentUser.email.toString())
    }
    async function signup(email, password) {
        return await auth.createUserWithEmailAndPassword(email, password).then(function () {

            auth.currentUser.getIdToken().then((token) => {
                users_authenticatedRef.doc(auth.currentUser.email).set({
                    uid: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    authtoken: token,
                    isverified: auth.currentUser.emailVerified === true ? "True" : "False",
                    loginstate: 'True',
                    lastloggedin: firebase.firestore.Timestamp.now(),
                    profileCounter: 5
                })
                users_authenticatedRef.doc(auth.currentUser.email).collection("settings").doc("billing").set({
                    currentPlan: "Freemium",
                    planEndingDate: firebase.firestore.Timestamp.now(),
                })
                users_authenticatedRef.doc(auth.currentUser.email).collection("settings").doc("notifications").set({
                    autoUpdates: true,
                    newDeals: true
                })
                users_authenticatedRef.doc(auth.currentUser.email).collection("settings").doc("tasks-settings").set({
                    "totalruntime": 0,
                    "voiceactivationleft": 3600,
                })
                return token
            })

            return true
        }).catch(function (error) {
            let errorMessage
            switch (error.code) {
                case "auth/email-already-in-use":
                    errorMessage = "Email already used. Go to login page."
                    break

                case "auth/invalid-email":
                    errorMessage = "Email address is invalid."
                    break

                case "auth/operation-not-allowed":
                    errorMessage = "Server error, please try again later."
                    break
                default:
                    errorMessage = "There was an unknown error. Please retry loggin in or try again later. If the problem persist then kindly contact us."
                    break
            }
            return errorMessage
        })

    }
    function isVerified(email) {
        auth.sendPasswordResetEmail(email).then(function () {
            return true
        }).catch(function (error) {
            var errorMessage = ""
            switch (error.code) {
                case "auth/invalid-email":
                    errorMessage = "The email is not valid."
                    break
                case "auth/user-not-found":
                    errorMessage = "No user found asscociated with this account"
                    break
                default:
                    errorMessage = "There was an unknown error. Please retry loggin in or try again later. If the problem persist then kindly contact us."
                    break
            }
            return errorMessage
        })

    }
    async function changeCurrentPlan(plan) {

        users_authenticatedRef.doc(currentUser.email).collection('settings').doc("billing").update({
            "currentPlan": plan
        }).then((response) => {
            users_authenticatedRef.doc(currentUser.email).collection('settings').doc('tasks-settings')
                .update({
                    "totalruntime": 0
                })
            return true
        })
    }
    async function getBillingInfo() {

        let sBrowser, sUsrAg = navigator.userAgent

        // The order matters here, and this may report false positives for unlisted browsers.

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox"
            // "Mozilla/5.0 (X11 Ubuntu Linux x86_64 rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
            sBrowser = "Samsung Internet"
            // "Mozilla/5.0 (Linux Android 9 SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera"
            // "Mozilla/5.0 (Macintosh Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer"
            // "Mozilla/5.0 (Windows NT 10.0 WOW64 Trident/7.0 .NET4.0C .NET4.0E Zoom 3.6.0 wbx 1.0.0 rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge"
            // "Mozilla/5.0 (Windows NT 10.0 Win64 x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium"
            // "Mozilla/5.0 (X11 Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari"
            firebase_firestore.enableNetwork()

            // "Mozilla/5.0 (iPhone CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
            // eslint-disable-next-line
            sBrowser = "unknown"
        }
        if (currentUser) {
            let data = firebase_firestore.collection("users_authenticated").doc(currentUser.email).collection('settings').get().then((data) => {
                // setuserProfile(data.data())
                // data.forEach((doc) => {
                //     alert(doc.data())
                // })
                setuserSettings(data.docs)
                // alert(JSON.stringify(data.docChanges))
                setuserPlan(data.docs[0].data().currentPlan)
                return data.docs
            }).catch((error) => {
                console.log(error)
            })
            return data
        }
    }

    async function generateAnalytics(data, fetch = false) {
        let totalTaskTime = 0
        if (fetch) {
            data = await getAnalytics()
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    let taskCount = parseFloat(data[key]['counter'])
                    let taskTime = parseFloat(data[key]["task_time"])
                    let analyticsData = taskTime * taskCount !== 0 ? taskTime * taskCount : taskTime
                    parseFloat(analyticsData).toFixed(5)
                    totalTaskTime += analyticsData
                    totalTaskTime = Math.round((totalTaskTime + Number.EPSILON) * 100) / 100
                    users_authenticatedRef.doc(currentUser.email)
                        .collection("settings")
                        .doc("tasks-settings").update({
                            "totalruntime": totalTaskTime
                        })
                }
            }
            setuserTaskAnalytics(totalTaskTime)
            if (totalTaskTime <= 600 && userPlan === "Freemium") {
                setIsTasksDisabled(true)
            }
            if (totalTaskTime <= 1200 && userPlan === "Standard") {
                setIsTasksDisabled(true)
            }

        }
        else {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    let taskCount = parseFloat(data[key]['counter'])
                    let taskTime = parseFloat(data[key]["task_time"])
                    let analyticsData = taskTime * taskCount !== 0 ? taskTime * taskCount : taskTime
                    parseFloat(analyticsData).toFixed(5)
                    totalTaskTime += analyticsData
                    totalTaskTime = Math.round((totalTaskTime + Number.EPSILON) * 100) / 100
                }
            }
            setuserTaskAnalytics(totalTaskTime)
        }


    }
    async function resetRunTime() {
        users_authenticatedRef.doc(currentUser.email)
            .collection("settings")
            .doc("tasks-settings")
            .update({
                totalruntime: 0
            })
    }
    async function getAnalytics() {

        if (currentUser) {
            return firebase_database.ref("users_authenticated").child(currentUser.uid).get()
                .then((data) => {
                    return data.val()
                })
        }
    }

    async function updateTaskBindings(bindings, taskName) {

        return firebase_database.ref("users_authenticated").child(currentUser.uid).child(taskName).update({
            task_bindings: bindings
        }).then((result) => {
            return true
        })
            .catch((error) => {
                return false
            })
    }

    async function getUserProfile() {
        console.log(currentUser.displayName);
        let data = users_authenticatedRef.doc(currentUser.email).get().then((data) => {
            return data.data()
        })
        return data
    }
    async function updateShuffleCounter(count) {

        users_authenticatedRef.doc(currentUser.email).update({
            profileCounter: count
        })
            .then(() => true)
            .catch(() => false)
    }
    async function updatePhotoURL(id) {
        if (id) {
            return auth.currentUser.updateProfile({
                photoURL: "https://avatars.dicebear.com/api/bottts/" + id + ".svg?background=%23e7e7e7"
            })
        }
        else {
            return auth.currentUser.updateProfile({
                photoURL: ""
            })
        }
    }
    async function setlogoutstate() {


        auth.currentUser.getIdToken().then((token) => {
            users_authenticatedRef.doc(auth.currentUser.email).set({
                uid: auth.currentUser.uid,
                email: auth.currentUser.email,
                authtoken: token,
                isverified: auth.currentUser.emailVerified === true ? "True" : "False",
                loginstate: 'False',
                lastloggedin: firebase.firestore.Timestamp.now()
            })
        })
        auth.signOut()
    }
    async function setloginstate(idtoken = undefined, google = false) {


        if (google === true) {
            auth.currentUser.getIdToken().then((tokenId) => {
                firebase_database.ref(`users_authenticated/${idtoken}/`).set({
                    "login": "True",
                    "uid": auth.currentUser.uid,
                    "email": auth.currentUser.email,
                    "isverified": auth.currentUser.emailVerified,
                    "authtoken": tokenId,
                })
            })
        }
        else {
            auth.currentUser.getIdToken().then((token) => {
                users_authenticatedRef.doc(auth.currentUser.email).set({
                    uid: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    authtoken: token,
                    isverified: auth.currentUser.emailVerified === true ? "True" : "False",
                    loginstate: 'True',
                    lastloggedin: firebase.firestore.Timestamp.now()
                })
            })
        }

    }
    async function login(email, password, isPersist) {
        if (isPersist) {

            await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

        }

        return await auth.signInWithEmailAndPassword(email, password).then(function () {

            auth.currentUser.getIdToken().then((token) => {
                users_authenticatedRef.doc(auth.currentUser.email).set({
                    uid: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    authtoken: token,
                    isverified: auth.currentUser.emailVerified === true ? "True" : "False",
                    loginstate: 'True',
                    lastloggedin: firebase.firestore.Timestamp.now()
                })
                return token
            })

            return true
        }).catch(function (error) {
            let errorMessage = ''
            switch (error.code) {
                case "auth/invalid-credential":
                    errorMessage = "Incorrect email/password combination"
                    break
                case "auth/wrong-password":
                    errorMessage = "Incorrect email/password combination"
                    break

                case "auth/user-not-found":
                    errorMessage = "No user found with this email"
                    break

                case "auth/user-disabled":
                    errorMessage = "This account is disabled please contact us to continue with this account."
                    break

                case "auth/too-many-requests":
                    errorMessage = "Too many requests made to log into this account."
                    break

                case "auth/invalid-email":
                    errorMessage = "Email address is invalid."
                    break

                default:
                    errorMessage = "There was an unknown error. Please retry loggin in or try again later. If the problem persist then kindly contact us."
                    break
            }
            return errorMessage
        })

    }
    async function setLogin(uid, id, email) {
        tokenId_user = id
        return await firebase_database.ref(`users_authenticated/${uid}`).update({
            loginstate: "True",
        }).then(function (result) {
            return true
        }).catch(function (err) {
            console.error(err)
            return false
        })
    }

    async function getLoginState(uid) {
        let result = await firebase_database.ref(`users_authenticated/${uid}`).get().then((snapshot) => {
            if (snapshot.exists) {
                const result = snapshot.val()
                return result
            }
        })

        return result
    }
    function emailVerification() {
        auth.currentUser.sendEmailVerification()
    }
    function logout() {
        try {

            auth.currentUser.getIdToken().then((token) => {
                users_authenticatedRef.doc(auth.currentUser.email).update({
                    authtoken: token,
                    displayName: auth.currentUser.displayName,
                    isverified: auth.currentUser.emailVerified === true ? "True" : "False",
                    loginstate: 'False',
                    lastloggedin: firebase.firestore.Timestamp.now()
                })
                return token
            })
            auth.signOut()
            return true
        }
        catch (err) {
            return false
        }

    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    useEffect(() => {
        getElectron()
        if (currentUser) {
            const unsubscribe = async () => {
                await getBillingInfo()
            }

            return unsubscribe
        }
        else {
            return
        }

        // eslint-disable-next-line
    }, [currentUser])

    const value = {
        currentUser,
        isTasksDisabled,
        taskNameList,
        settaskNameList,
        changeList,
        updateTaskList,
        getTaskList,
        updateTaskBindings,
        userTaskAnalytics,
        setuserTaskAnalytics,
        generateAnalytics,
        getAnalytics,
        resetRunTime,
        userSettings,
        setuserSettings,
        changeCurrentPlan,
        userPlan,
        setuserPlan,
        planDetails,
        userProfileContent,
        setuserProfileContent,
        isElectron,
        signup,
        login,
        logout,
        isLoggedIn,
        emailVerification,
        isVerified,
        forgotPassword,
        getUserId,
        updateUserPasswd,
        googleSignin,
        setLogin,
        getLoginState,
        setTokenId,
        getTokenId,
        setloginstate,
        setlogoutstate,
        getUserProfile,
        getBillingInfo,
        updatePhotoURL,
        updateShuffleCounter
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}