const steps = [
    {
        selector: '[data-tour="TasksBtn"]',
        content: "Tasks Pane"
    },
    {
        selector: '[data-tour="AddTaskBtn"]',
        content: "You can create custom tasks from here.",
        highlightedSelectors: [".chakra-modal__content"],
        mutationObservables: ['settings-step-selector']
    },
    {
        selector: '.chakra-modal__content',
        content: "Just fill in the details below and click 'Create'. It's that easy."
    },
    {
        selector: '[data-tour="CloseDialog"]',
        content: "Close",
    },
    {
        selector: '[data-tour="Tasks"]',
        content: "Your custom tasks will apear here. You can run, delete and share it with your friends.",
        mutationObservables: ['settings-step-selector']

    },
    {
        selector: '[data-tour="Microphone"]',
        content: "Hold microhpone button to use speech automated tasks and release to stop.",

    },
    {
        selector: '[data-tour="SettingBtn"]',
        content: "Manage your profile and application settings.",
    }
]
export default steps;