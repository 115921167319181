import React, { useState } from 'react'
import { MdClose, MdCropSquare, MdMinimize, } from "react-icons/md";
import { Box, HStack, IconButton, Img, Spacer } from '@chakra-ui/react';
import './Css/menucomponent.css'
import { useAuth } from '../../../Context/AuthContext';
import appLogo from "../../../Assets/favicon-32x32.png"

function MenuComponent() {
    const { isElectron } = useAuth()
    const [isMaximize, setisMaximize] = useState(true)
    return (
        isElectron ?
            <Box position="fixed" bgColor='#304663' width="100vw" height={'32px'}>
                <HStack spacing={'0px'} ml='2'>
                    {/* <Text color='white'> Icon Here Ava </Text>*/}
                    <Img height={'32px'} width={'32px'} src={appLogo}></Img>
                    {/* <Text variant='titlebarBtn'>Ava</Text> */}
                    {/* <IconButton variant='titlebarbtn' icon={}></IconButton> */}
                    <Spacer className='title-bar'></Spacer>
                    <IconButton
                        onClick={() => {
                            window.api.minimize()
                        }}
                        variant={'windowsBtn'} icon={<MdMinimize color='white' />}></IconButton>
                    <IconButton
                        onClick={() => {
                            if (isMaximize) {
                                setisMaximize(false)
                                window.api.maximize(false)
                            }
                            else {
                                setisMaximize(true)
                                window.api.maximize(true)
                            }
                        }}
                        variant={'windowsBtn'} icon={<MdCropSquare color='white' />}></IconButton>
                    <IconButton
                        onClick={() => {
                            window.api.close()
                        }}
                        variant={'windowsCloseBtn'} icon={<MdClose color='white' />}></IconButton>

                </HStack>
            </Box > :
            <React.Fragment></React.Fragment>

    )
}

export default MenuComponent