import { JSONEditor } from "vanilla-jsoneditor"
import { useEffect, useRef } from "react"
import React from 'react'
import "./editor.css"


export default function TaskEditor(props) {
    const refContainer = useRef(null)
    const refEditor = useRef(null)
    console.log(props.content)
    // eslint-disable-next-line
    const schema = {
        "type": "array",
        "items": [
            { "type": "string" },
            {
                "type": "object",
                "properties": {
                    "openwithstart": {
                        "type": "string"
                    }
                },
            },
            {
                "type": "object",
                "properties": {
                    "timeSleep": {
                        "type": "object",
                        "properties": {
                            "sleep": {
                                "type": "number"
                            }
                        },
                        "required": [
                            "sleep"
                        ]
                    }
                },
            },
            {
                "type": "object",
                "properties": {
                    "moveTo": {
                        "type": "object",
                        "properties": {
                            "x": {
                                "type": "integer"
                            },
                            "y": {
                                "type": "integer"
                            }
                        },
                        "required": [
                            "x",
                            "y"
                        ]
                    }
                },
            },
            {
                "type": "object",
                "properties": {
                    "leftClick": {
                        "type": "object",
                        "properties": {
                            "x": {
                                "type": "integer"
                            },
                            "y": {
                                "type": "integer"
                            }
                        },
                        "required": [
                            "x",
                            "y"
                        ]
                    }
                },

            }
        ]
    }

    useEffect(() => {
        // create editor
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props: {
                readOnly: true,
                update(val) {
                    console.log("update", val)
                },
                onRenderMenu(menu) {
                    console.log("onRenderMenu", menu)
                },
                onChange(value, previousValue, patchValue) {
                    console.log("====================================")
                    console.log(value, previousValue, patchValue)
                    // Validate the JSON field 
                    let path = patchValue.undo[0].path
                    let fieldName = path.split("/").pop()
                    console.log('====================================')
                    console.log(path, fieldName)
                    console.log('====================================')
                    switch (fieldName) {
                        case "sleep":
                            refEditor.current.set(previousValue)
                            break
                        case "hotKeyPress":
                            refEditor.current.set(previousValue)
                            break
                        default:
                            console.log("Task after change:", value)
                            props.callback(value.json['editedTask'])
                            refEditor.current.set(value.json['editedTask'][0])
                    }
                    // JSON schema validation
                    // let ajv = new Ajv({ allErrors: true })
                    // const validate = ajv.compile(schema)
                    // const valid = validate(value.json.editedTask)
                    // if (!valid) {
                    //     console.log("There was an error", validate.errors)
                    //     value = previousValue
                    //     console.log(refEditor.current)
                    //     refEditor.current.set(value)
                    // }
                    // createAjvValidator(schema, value)

                }
            },

        })

        return () => {
            // destroy editor
            if (refEditor.current) {
                console.log("destroy editor")
                refEditor.current.destroy()
                refEditor.current = null
            }
        }
    }, [props])

    // update props
    useEffect(() => {
        if (refEditor.current) {
            refEditor.current.updateProps(props)
        }
    }, [props])

    return <div style={{ width: "30vw", height: "40vh" }} className="svelte-jsoneditor-react" ref={refContainer}></div>
}
