import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { Routes } from './Routes/Routes';
import theme from './components/Theme/Theme';
import { AuthProvider } from './Context/AuthContext';
import { TourProvider } from '@reactour/tour'
import steps from './steps';
import MenuComponent from './components/Dashboard/widgets/MenuComponent';
import { HelmetProvider } from 'react-helmet-async';
import { Button } from 'react-bootstrap';


const radius = 10

function App() {
  // window.screen.lockOrientation("landscape");

  return (
    <HelmetProvider>
      
      <ChakraProvider theme={theme}>
        <TourProvider
          steps={steps}
          disableKeyboardNavigation
          disableDotsNavigation
          showNavigation={true}
          onClickClose={false}
          onClickMask={false}
          nextButton={({
            Button,
            currentStep,
            stepsLength,
            setIsOpen,
            setCurrentStep,
          }) => {
            const last = currentStep === stepsLength - 1
            return (
              <Button
                hideArrow={last}
                onClick={() => {
                  if (last) {
                    setIsOpen(false)
                  }
                  else if (currentStep === 1 || currentStep === 6 || currentStep === 3) {
                    console.log("Here");
                    return
                  }
                  else {
                    setCurrentStep(s => (s === steps.length - 1 ? 0 : s + 1))
                  }
                }}
              >
                {last ? 'Finish' : ""}
              </Button>
            )
          }}
          prevButton={({
            currentStep,
            stepsLength,
            setIsOpen,
            setCurrentStep }) => {
            const first = currentStep === 0
            return (
              <Button
                onClick={() => {
                  if (first) {
                    setCurrentStep(s => steps.length - 1)
                  } else {
                    setCurrentStep(s => s - 1)
                  }
                }}
              >
              </Button>
            )
          }}
          styles={{
            popover: base => ({
              ...base,
              '--reactour-accent': '#304663',
              borderRadius: radius,
            }),
            badge: base => ({ ...base, left: 'auto', right: '-0.8125em' }),
            controls: base => ({ ...base, marginTop: 14 }),
            close: base => ({ ...base, right: 'auto', left: 8, top: 8 }),
            dot: base => ({
              ...base,
              display: "none"
            }),
          }}>
          <AuthProvider>
            <div>
              <MenuComponent></MenuComponent>
              <Routes />
            </div>
          </AuthProvider>
        </TourProvider >
      </ChakraProvider >
    </HelmetProvider>
  );
}

export default App;
