import React, { useEffect } from 'react'
import TaskEditor from './TaskEditor';
import {
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tooltip,
    VStack
} from '@chakra-ui/react';
import { useAuth } from '../../../Context/AuthContext';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik'
import { Home, Settings } from '@mui/icons-material';
import TimePicker from 'react-time-picker';
import './tasks.css'
import Select from "react-select";
import { useBeforeunload } from 'react-beforeunload';
import { useHistory } from 'react-router-dom';

function EditTasks(props) {
    const task = React.useState(JSON.parse(props.location.state.taskBindings.replaceAll("'", '"')))
    const taskMetadata = React.useState(props.location.state.taskMetadata)
    console.log(taskMetadata);
    const { updateTaskBindings, getTaskList } = useAuth()
    const [saveTask, setSaveTask] = React.useState(false)
    const [tasktime, setTimer] = React.useState(taskMetadata[0].schedule_time.toString())
    const history = useHistory()
    
    const pageRefConf = useBeforeunload((event) => {
        if (true) {
            setTimeout(() => {
                window.location = "/dashboard"
                // props.history.push('/dashboard')
            }, 0)
            window.onbeforeunload = null;
        }
    })
    useEffect(() => {
        window.addEventListener('beforeunload', pageRefConf)
        return () => {
            window.removeEventListener('beforeunload', pageRefConf)
        }
    }, [pageRefConf])

    const FormSelect = ({ defaultValue, name, options }) => {
        // eslint-disable-next-line
        const [field, meta, helpers] = useField(name);
        const taskList = []
        if (options) {
            options.map((val) => {
                return taskList.push({ value: val.name, label: val.name })
            })
        }
        else {
            history.push('/dashboard');
        }

        const customSelectStyle = {
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';
                return { ...provided, opacity, transition };
            },
            option: (provided, state) => {
                return { ...provided, color: state.isSelected ? "white" : "black", backgroundColor: state.isSelected ? "#304663" : 'white' }
            },
            control: (provided, state) => {
                return { ...provided, width: "100%" }
            }
        }
        return (
            <>
                <Select
                    styles={customSelectStyle}
                    name={name}
                    value={field.value}
                    defaultInputValue={defaultValue ? defaultValue : ""}
                    onChange={(value) => helpers.setValue(value)}
                    options={taskList}
                    onBlur={() => helpers.setTouched(true)}
                />
                <ErrorMessage name={name} />
            </>
        );
    };

    async function saveJsonData(data) {
        console.log("Saving the tast:,", data[0]);
        setSaveTask(data[0])
        let result = await updateTaskBindings(data[0], taskMetadata[0].taskName)
        console.log(result);
    }

    return (
        <div>
            <div style={{
                paddingTop: "5vh",
            }}>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        variant='outline'
                    />
                    <MenuList>
                        <MenuItem icon={<Home />} command='⌘T' onClick={() => {
                            props.history.push('/dashboard');
                            setSaveTask(saveTask ? false : true)
                            console.log(saveTask);
                        }}>
                            Home
                        </MenuItem>
                        <MenuItem icon={<Settings />} command='⌘N' onClick={() => {
                            props.history.push('/settings');
                            setSaveTask(saveTask ? false : true)
                            console.log(saveTask);
                        }}>
                            Settings
                        </MenuItem>

                    </MenuList>
                </Menu>

            </div>
            <Formik
                initialValues={{ taskName: taskMetadata[0].taskName, description: taskMetadata[0].taskDescription, checked: taskMetadata[0].schedule.split(","), openapp: "", taskTimer: taskMetadata[0].schedule_time, prerun: taskMetadata[0].prerun, postrun: taskMetadata[0].postrun }}
                onSubmit={async (values, actions) => {
                    actions.setSubmitting(true)
                }}
            >
                {(props) => (
                    <VStack align=''>
                        <Form style={{
                            marginLeft: "10vw",
                            marginRight: "10vw"
                        }}>
                            <HStack>
                                <Field name="taskName" >
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.taskName && form.touched.taskName}>
                                            <FormLabel htmlFor="taskName">Task Name</FormLabel>
                                            <div className="input">
                                                <Input maxLength={'20'} {...field} id="taskName" className="limited" />
                                                <label className="counter">{field.value.length}/20</label>
                                            </div>
                                            <FormErrorMessage>{form.errors.taskName}</FormErrorMessage>
                                        </FormControl>
                                    )}

                                </Field>
                                <Field name="description">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.description && form.touched.description}>
                                            <FormLabel htmlFor="description">Description</FormLabel>
                                            <div className="input">
                                                <Input maxLength='40' {...field} id="description" type={'text'} />
                                                <label className="counter">{field.value.length}/40</label>
                                            </div>
                                            <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                                        </FormControl>

                                    )}
                                </Field>


                            </HStack>
                            <HStack mt={5}>
                                <Field name='prerun'>
                                    {({ field, form }) => (
                                        <FormControl mt={'2'}>
                                            <Center>
                                                <HStack>
                                                    <Tooltip variant={'primary'} label="Run a task before this task">
                                                        <FormLabel htmlFor="prerun">Pre Run</FormLabel>
                                                    </Tooltip>
                                                    <br></br>
                                                    <FormSelect defaultValue={taskMetadata[0].prerun} name='prerun' options={getTaskList()}></FormSelect>

                                                </HStack>
                                            </Center>

                                            <FormErrorMessage>{form.errors.openapp}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='postrun'>
                                    {({ field, form }) => (
                                        <FormControl mt={'4'}>
                                            <Center>
                                                <HStack>
                                                    <Tooltip variant={'primary'} label="Run a task after this task">
                                                        <FormLabel htmlFor="postrun">Post Run</FormLabel>
                                                    </Tooltip>
                                                    <br></br>
                                                    <FormSelect defaultValue={taskMetadata[0].postrun} name='postrun' options={getTaskList()}></FormSelect>

                                                </HStack>
                                            </Center>

                                            <FormErrorMessage>{form.errors.openapp}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </HStack>

                            <VStack mt={5} spacing={5}>
                                <HStack mt={'2'} alignContent={'space-between'}>
                                    <FormLabel htmlFor="postrun" id="checkbox-group">Select days</FormLabel>

                                    {/* <div id="checkbox-group">Select days</div> */}
                                    <div className="weekDays-selector" role="group" aria-labelledby="checkbox-group">

                                        <label>
                                            <Field type="checkbox" id='weekday-sun' className="weekday" name="checked" value="Sun" ischecked='true' />
                                            <label htmlFor="weekday-sun">S</label>
                                        </label>
                                        <label>
                                            <Field type="checkbox" id='weekday-mon' className="weekday" name="checked" value="Mon" />
                                            <label htmlFor="weekday-mon">M</label>
                                        </label>
                                        <label>
                                            <Field type="checkbox" id='weekday-tue' className="weekday" name="checked" value="Tue" />
                                            <label htmlFor="weekday-tue">T</label>
                                        </label>
                                        <label>
                                            <Field type="checkbox" id='weekday-wed' className="weekday" name="checked" value="Wed" />
                                            <label htmlFor="weekday-wed">W</label>
                                        </label>
                                        <label>
                                            <Field type="checkbox" id='weekday-thu' className="weekday" name="checked" value="Thu" />
                                            <label htmlFor="weekday-thu">T</label>
                                        </label>
                                        <label>
                                            <Field type="checkbox" id='weekday-fri' className="weekday" name="checked" value="Fri" />
                                            <label htmlFor="weekday-fri">F</label>
                                        </label>
                                        <label>
                                            <Field type="checkbox" id='weekday-sat' className="weekday" name="checked" value="Sat" />
                                            <label htmlFor="weekday-sat">S</label>
                                        </label>
                                    </div>

                                </HStack>
                                <TimePicker onChange={(val) => {
                                    setTimer(val)
                                    console.log("Time picker value: ", val);
                                }} value={tasktime} />
                            </VStack>
                            <Center>
                                <Button
                                    my={4}
                                    w={'10vw'}
                                    colorScheme="teal"
                                    variant={'Btnaccent'}
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    textAlign={'center'}
                                    onClick={() => {
                                        setSaveTask(saveTask ? false : true)
                                        console.log(saveTask);
                                        history.push('/dashboard')
                                    }}>Save</Button>
                            </Center>
                            <Center>
                                <TaskEditor
                                    callback={saveJsonData}
                                    content={{
                                        json: {
                                            editedTask: task[0]
                                        }
                                    }}
                                >
                                </TaskEditor>
                            </Center>
                        </Form>
                    </VStack>
                )}
            </Formik>


        </div >

    );
}

export default EditTasks