import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import "firebase/messaging"
import "firebase/compat/app-check"
import { getMessaging, isSupported } from 'firebase/messaging'

firebase.initializeApp({
    apiKey: "AIzaSyBtitwcE_As4_dMv8GQrXOgfi2ePwiEhbQ",
    authDomain: "ava-daemon.firebaseapp.com",
    databaseURL: "https://ava-daemon.firebaseio.com",
    projectId: "ava-daemon",
    storageBucket: "ava-daemon.appspot.com",
    messagingSenderId: "41961847947",
    appId: "1:41961847947:web:f65107ad2f44e29c9aa88e",
    measurementId: "G-CY5HDPPDY5"
})
export const fireabase_App = firebase.app()
export const auth = firebase.app().auth()
export const firestore = firebase.firestore()
export const firebase_storage = firebase.storage()
export const firebase_functions = firebase.functions()
export const firebase_database = firebase.database()
export const firebase_firestore = firebase.firestore()
export const appCheck = firebase.appCheck();

appCheck.activate("6Lc7UM4fAAAAALCK8dcsMbc8-rDiIxe0i-pXe4LO")

// async function check() {
//     return isSupported().then(result => {
//         if (!result) {
//             console.log("Not Supported");
//             Promise.resolve(false)
//         }

//         else {
//             console.log("Messaging available in web")
//             Promise.resolve(true)
//         }
//     })
// }
const checkMessaging = new Promise(function (resolve, reject) {
    try {
        window.api.isElectron().then((rs) => {

            resolve(true)
        })
    }
    catch (error) {

        isSupported().then(result => {
            if (!result) {

                resolve(false)
            }
            else {

                resolve(true)
            }
        })
    }

})
export const firebase_messaging = checkMessaging.then((rs) => {

    if (rs) {
        return getMessaging(fireabase_App)
    }
    else {
        return undefined
    }
})
// firebase_messaging.getToken({ vapidKey: "BA3D0SI3iyhBwc1NHaYnZZ3crG3sPhNguTnctjh7T9hAhytFE_fYaa_TN4i06Qi3mQTpQjmDjUXs9PWqXadI9_0" })

// firebase_firestore.enablePersistence()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

// firebase_functions.useEmulator("localhost", 5001)
